body {
  /* margin: 0; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

table {
  width: 100%;
  padding-bottom: 2em;
}

tr {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

th {
  /* width: 50%; */
}

td {
  text-align: left;
}
